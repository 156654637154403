input {
    width: 20%;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin: 10px;
  }

  button {
    background-color: #5bc0de;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 10px;
    margin: 10px;
  }